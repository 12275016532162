<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">Create Car Park</div>
      </v-card-title>
      <v-card-text>
        <template>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-select
                v-model="carPark.siteId"
                :items="sites"
                item-text="name"
                item-value="id"
                label="Select Site"
            ></v-select>
            <v-text-field label="CarPark Name" v-model="carPark.name" required></v-text-field>
            <v-text-field label="Capacity" type="number" v-model="carPark.capacity" required></v-text-field>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()">Cancel</v-btn>
        <v-btn @click="submit()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import toastr from "toastr";

export default {
  name: "carpark-create",
  data: () => ({
    carPark: {
      name: '',
      capacity: '',
      siteId: ''
    },
    sites: [],

    valid: true,
  }),
  methods: {
    cancel() {
      this.$router.back()
    },

    submit() {
      this.$store.dispatch('car-parks/create', {
        name: this.carPark.name,
        capacity: this.carPark.capacity,
        siteId: this.carPark.site
      }).then((result) => {
        toastr.success('Successfully added an organisation.')
        this.$router.back()
      }).catch((error) => {
        toastr.error(error);
      })
    },

    /**
     * Populate the site list for the dropdown
     */
    getSiteList() {
      this.$store.dispatch('sites/find').then((result) => {
        this.sites = result.data;
        this.carPark.siteId = result.data[0].id ?? null;
      });
    }
  },
  mounted() {
    this.getSiteList()
  }
}
</script>

<style scoped>

</style>